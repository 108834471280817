import { Controller } from "@hotwired/stimulus";
import axios from 'axios'

export default class extends Controller {
  static targets = ['input', 'status', 'itemHtml', 'progressbar']
  static values = {
    createUrl: String
  }

  connect() {
    this.bindFileInput()
  }

  bindFileInput() {
    $(this.inputTarget).on('change', e => {
      const file = e.target.files[0]
      if (!file) return;

      let lastDot = file.name.lastIndexOf('.')
      let ext = file.name.slice(lastDot + 1)
      let name = file.name.slice(0, lastDot)
      let fileId

      this.updateItemHtml({ name, ext, status: 'Preparing...' })
      this.getPresignedUrl({ extension: ext, name: name }).then(response => {
        const url = response.data.presigned_url
        fileId = response.data.id
        return axios.put(response.data.presigned_url, file, {
          headers: { 'Access-Control-Allow-Origin': '*' },
          onUploadProgress: progressEvent => {
            if (!this.hasProgressbarTarget) return;

            let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            this.progressbarTarget.style.width = `${percentCompleted}%`
            this.updateUploadStatus(`Uploading... ${percentCompleted}%`)

            if (percentCompleted === 100) {
              this.progressbarTarget.parentElement.style.display = 'none'
              this.updateUploadStatus(`Finishing...`)
            }
          }
        })
      })
      .then(response => {
        return this.updateFile(fileId)
      })
      .then(response => {
        if (response.data.success) {
          this.updateUploadStatus(`<a href="${response.data.download_url}" class="text-success none-underline-link"><i class="fa fa-download"></i></a>`)
          this.itemHtmlTarget.querySelector('.status').classList.remove('small')
        }
      })
    })
  }

  updateItemHtml({ name, ext, status }) {
    if (!this.hasItemHtmlTarget) return;

    this.itemHtmlTarget.querySelector('.filename').innerHTML = `${name}.${ext}`
    this.updateUploadStatus(status)

    this.itemHtmlTarget.classList.remove('d-none')
  }

  updateUploadStatus(status) {
    this.itemHtmlTarget.querySelector('.status').innerHTML = status
  }

  getPresignedUrl(params) {
    return axios.post(
      this.createUrlValue, params,
      { headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
      } }
    )
  }

  updateFile(id) {
    return axios.put(
      `${this.createUrlValue}/${id}`, { uploaded: true },
      { headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
      } }
    )
  }
}
