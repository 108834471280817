import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.currentImage = null
  }

  fileChanged(e) {
    if (!e.target.files.length) {
      return;
    }
    let file = e.target.files[0]
    var reader = new FileReader();
    $(this.currentImage).css({ backgroundImage: `` })
    reader.onload = () => {
      if (file.type === 'video/mp4') {
        $(this.currentImage).find('video').prop('src', reader.result)
      } else {
        $(this.currentImage).css({ backgroundImage: `url(${reader.result})` })
      }
      $(this.currentImage).find('input').prop('value', reader.result)
    };
    reader.readAsDataURL(file);
  }

  labelClicked(e) {
    this.currentImage = $(e.target).parents('.review')
  }
}
