import { Controller } from "@hotwired/stimulus"
import { each } from 'lodash'

export default class extends Controller {
  static values = {
    mobileColumns: {
      type: Number,
      default: 2
    },
    desktopColumns: {
      type: Number,
      default: 4
    },
    dots: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.currentIndex = 0
    this.loadMoreTriggered = false
    this.nextPage = 2
    this.owl = this.initCarousel()
  }

  adjustArrowPosition() {
    if (!$('.owl-item .room-design').length) return;

    const top = $('.owl-item .room-design').height() / 2
    $('.owl-prev, .owl-next').css('top', top)
  }

  onCarouselTranslated(e) {
    if (!$('.owl-item .observer').length) return;

    this.observer = new IntersectionObserver((entries) => {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      if (entries[0].isIntersecting && !this.loadMoreTriggered) {
        this.loadMoreTriggered = true
        console.log('Element has just become visible in screen');
        this.loadMore(() => {
          this.owl.owlCarousel('remove', e.item.count - 1)
          this.loadMoreTriggered = false
        })
      }
    }, { threshold: [0] });
    this.observer.observe($('.owl-item .observer')[0])
  }

  initCarousel() {
    return $(this.element).owlCarousel({
      loop: false,
      margin: 10,
      responsive:{
        0: {
          items: this.mobileColumnsValue
        },
        768: {
          items: this.desktopColumnsValue
        }
      },
      dots: this.dotsValue,
      nav: true,
      navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
      onInitialized: () => this.adjustArrowPosition(),
      onResized: () => this.adjustArrowPosition(),
      onChanged: (e) => this.onCarouselTranslated(e)
    })
  }

  loadMore(cb = null) {
    const url = new URL(window.location.href)
    const urlParams = new URLSearchParams(window.location.search);
    let searchParams = url.search
    if (searchParams.length) {
      searchParams += `&page=${this.nextPage}`
    } else {
      searchParams = `?page=${this.nextPage}`
    }
    $.getJSON(`${url.origin}${url.pathname}${searchParams}`).then((res) => {
      if (!!cb) cb();
      this.nextPage++
      let observer = $('.owl-carousel .observer')
      if (!!res.entries.length) {
        each(res.entries, entry => {
          this.owl.owlCarousel('add', entry);
        })
      }
      this.owl.owlCarousel('update');
    })
  }
}
